import Vue from "vue";

export async function index(lead_number, params) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/annotations`,
    {
      params,
    }
  );

  return response.data;
}

export async function store(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_number}/annotations`,
    payload
  );

  return response.data;
}

export async function createReminder(annotation_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/annotations/${annotation_id}/reminders`,
    payload
  );

  return response.data;
}

export async function updateReminder(annotation_id, reminder_id, payload) {
  const response = await Vue.prototype.$http.put(
    `/annotations/${annotation_id}/reminders/${reminder_id}`,
    payload
  );

  return response.data;
}

export async function deleteReminder(annotation_id, reminder_id) {
  const response = await Vue.prototype.$http.delete(
    `/annotations/${annotation_id}/reminders/${reminder_id}`
  );

  return response.data;
}

export async function update(id, payload) {
  const response = await Vue.prototype.$http.put(`/annotations/${id}`, payload);

  return response.data;
}

export async function show(id) {
  const response = await Vue.prototype.$http.get("/annotations/" + id);

  return response.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete("/annotations/" + id);

  return true;
}
