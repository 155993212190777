<template>
  <div class="annotations">
    <v-card>
      <v-card-title>
        {{ $capitalize($tc("model.annotation_annotations_title")) }}
        <v-spacer></v-spacer>
        <v-btn icon @click="getAnnotations" :loading="loading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div
          v-for="(annotation, index) in annotations"
          :key="annotation ? annotation.id : 'annotation_' + index"
        >
          <v-list-item
            two-line
            v-if="isPreviousCommentFromTheSameUser(annotation, index)"
          >
            <v-list-item-avatar>
              <user-avatar
                :avatar_url="
                  annotation.sender ? annotation.sender.avatar_url : null
                "
                :user_name="
                  annotation.sender ? annotation.sender.name : 'Sistema'
                "
                :user_id="annotation.sender ? annotation.sender.id : null"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="annotation.sender ? annotation.sender.name : 'Sistema'"
              />
              <v-list-item-subtitle
                v-text="annotation.sender ? annotation.sender.email : ''"
              />
            </v-list-item-content>
          </v-list-item>

          <div v style="max-width: 95%" class="d-inline-block mb-3 pl-15 ml-2">
            <v-card flat color="#f1f1f1">
              <v-card-text>
                <div v-if="annotation.is_deleted" class="font-italic">
                  <v-icon small>mdi-cancel</v-icon> Anotação apagada
                </div>
                <div
                  v-else
                  v-html="formatAnnotationMessage(annotation.message)"
                ></div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="py-0">
                <v-spacer></v-spacer>
                <v-chip
                  class="ml-1"
                  x-small
                  v-for="reminder in annotation.reminders"
                  @click="openReminderDialog(annotation, reminder)"
                  :key="reminder.id"
                >
                  <v-icon x-small left>mdi-clock</v-icon>
                  <span v-text="getReminderDate(reminder)" class="ml-1"></span>
                  <v-icon
                    x-small
                    right
                    @click.stop.prevent="clearReminder(reminder)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
                <v-btn
                  v-if="annotation.can_update"
                  icon
                  small
                  class="v-btn-margin"
                  @click="openDialog(annotation)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="!annotation.is_deleted"
                  icon
                  small
                  class="v-btn-margin"
                  @click="openReminderDialog(annotation)"
                >
                  <v-icon small>mdi-bell-plus</v-icon>
                </v-btn>
                <v-btn
                  v-if="annotation.can_destroy"
                  icon
                  small
                  class="v-btn-margin"
                  @click="deleteAnnotation(annotation.id)"
                >
                  <v-icon small>mdi-delete </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-list-item-subtitle>
              <span
                >{{ annotation.created_at }}
                <span v-if="annotation.is_edited && !annotation.is_deleted">
                  - Editado</span
                >
              </span>
            </v-list-item-subtitle>
          </div>
        </div>

        <v-divider></v-divider>

        <ScopeProvider scope="leads.annotations.add">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="sendAnnotation">
              <v-list-item three-line>
                <v-list-item-avatar>
                  <user-avatar
                    :avatar_url="user.avatar"
                    :user_name="user.name"
                    :user_id="user.sub"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <ValidationProvider
                    name="message"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      :placeholder="
                        $capitalize($tc('model.annotation_send_note_text'))
                      "
                      filled
                      name="message"
                      auto-grow
                      v-model="form.message"
                      :error-messages="errors"
                      :disabled="sending_message"
                    ></v-textarea>
                  </ValidationProvider>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="text-right mr-4">
                  <v-btn
                    :loading="sending_message"
                    type="submit"
                    color="primary"
                  >
                    <v-icon left>mdi-send</v-icon>
                    {{ $capitalize($tc("model.annotation_send_button")) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </ScopeProvider>
        <edit-comment-annotation
          ref="editCommentAnnotation"
          @update:annotation="handleAnnotationUpdate"
        />
      </v-card-text>
    </v-card>

    <ReminderDialog
      v-model="reminder_dialog"
      :reminder="reminder"
      @submit="($event) => saveReminder($event)"
      @close="resetReminderAnnotation"
    />
  </div>
</template>

<script>
import {
  formatDateTimeWithDuration,
  formatDateTime,
  formatTime,
} from "@/tools/date";
import {
  index as indexAnnotations,
  store as storeAnnotations,
  destroy,
  createReminder,
} from "@/services/annotations";
import ReminderDialog from "@/components/ReminderDialog";
import UserAvatar from "@/components/UserAvatar";
import ScopeProvider from "@/components/ScopeProvider";
import ReminderMixin from "@/mixins/ReminderMixin";
import EditCommentAnnotation from "@/components/Lead/EditCommentAnnotation";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import moment from "moment";

export default {
  components: {
    UserAvatar,
    ScopeProvider,
    ReminderDialog,
    EditCommentAnnotation,
  },
  mixins: [ReminderMixin, ToastsMixin],
  data: () => ({
    annotations: [],
    loading: false,
    form: {},
    sending_message: false,
    reminder_dialog: false,
    reminder_annotation: null,
    reminder: null,
    showEditButtons: true,
  }),
  methods: {
    handleAnnotationUpdate(annotation) {
      this.annotations = this.annotations.map((rec) => {
        if (rec.id === annotation.id) {
          return annotation;
        } else {
          return rec;
        }
      });
    },
    resetReminderAnnotation() {
      this.reminder_annotation = null;
      this.reminder = null;
    },
    openReminderDialog(annotation, reminder = null) {
      this.resetReminderAnnotation();
      this.reminder_dialog = true;
      this.reminder_annotation = annotation;
      this.reminder = reminder;
    },
    handleEnter(event) {
      if (event.ctrlKey) {
        this.sendAnnotation();
      } else {
        this.form.message = this.form.message + "\n";
      }
    },
    toDate(raw_date) {
      const date = this.$moment(raw_date);

      return formatDateTimeWithDuration(date);
    },
    resetForm() {
      this.form = {};
      this.$refs.form.reset();
    },
    isPreviousCommentFromTheSameUser(annotation, index) {
      return (
        !this.annotations[index - 1] ||
        this.annotations[index - 1].sender_id !== annotation.sender_id
      );
    },
    formatAnnotationMessage(message) {
      // RegExp representa o \n
      return message.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    async getAnnotations() {
      this.annotations = [];
      this.loading = true;

      const lead_number = this.$route.params["lead_number"];

      try {
        const response = await indexAnnotations(lead_number);
        this.annotations = response.data;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao exibir anotações",
          color: "red",
        });
      } finally {
        this.loading = false;
      }
    },
    openDialog(annotation) {
      this.$refs.editCommentAnnotation.open(annotation.id, annotation.message);
    },
    async deleteAnnotation(annotationId) {
      if (!confirm("Tem certeza que deseja excluir a anotação?")) {
        return;
      }
      try {
        const response = await destroy(annotationId);
        this.toastSuccess("Anotação removida com sucesso!");
        this.dialog = false;
        this.is_deleted = true;
        this.showEditButtons = false;
        this.getAnnotations();
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ?? "Erro ao excluir anotação.";
        this.toastError(errorMessage);
      }
    },
    getReminderDate(reminder) {
      let reminder_date = null;
      if (reminder.date) {
        const datetime = this.$moment(reminder.date);

        const date = datetime.clone().startOf("day");
        const day_diff = date.diff(this.$moment().startOf("day"), "days");

        if (day_diff === 1) {
          reminder_date =
            this.$capitalize(this.$tc("model.reminder_tomorrow_annotation")) +
            formatTime(datetime);
        } else if (day_diff === 0) {
          reminder_date =
            this.$capitalize(this.$tc("model.reminder_today_annotation")) +
            formatTime(datetime);
        } else {
          reminder_date = formatDateTime(datetime);
        }
      }
      return reminder_date;
    },
    async sendAnnotation() {
      const payload = {
        ...this.form,
      };

      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.sending_message = true;

      const lead_number = this.$route.params["lead_number"];

      try {
        const response = await storeAnnotations(lead_number, payload);
        this.annotations = response;
        this.resetForm();

        this.$store.commit("sendMessage", {
          text: "Anotação enviada",
          color: "green",
        });
        this.getAnnotations();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao enviar anotação",
          color: "red",
        });
      } finally {
        this.sending_message = false;
      }
    },
    async saveReminder(payload) {
      if (this.reminder) {
        this.updateReminder(this.reminder?.id, payload, (reminder) => {
          this.updateAnnotationReminders(
            reminder.remindable.id,
            reminder.remindable.reminders
          );
        });
      } else {
        this.createReminder(
          () => createReminder(this.reminder_annotation?.id, payload),
          (reminder) =>
            this.updateAnnotationReminders(
              reminder.remindable.id,
              reminder.remindable.reminders
            )
        );
      }
    },
    clearReminder(reminder) {
      this.deleteReminder(reminder.id, () => {
        this.annotations = this.annotations.map((annotation) => {
          return {
            ...annotation,
            reminders: annotation.reminders.filter((item) => {
              return item.id !== reminder.id;
            }),
          };
        });
      });
    },
    updateAnnotationReminders(annotation_id, reminders) {
      this.isMasterUser(),
        (this.annotations = this.annotations.map((rec) => {
          if (rec.id == annotation_id) {
            return {
              ...rec,
              reminders,
            };
          } else {
            return rec;
          }
        }));
    },
    isMasterUser() {
      return this.user.master;
    },
    mounted() {
      this.getAnnotations();
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },
  created() {
    this.getAnnotations();
  },
};
</script>

<style>
.v-btn-margin {
  margin-right: -12px;
}
</style>
